const environment = process.env.REACT_APP_VERCEL_ENV;

console.log(environment, "envriormneafdafsfds");
const config = {
  marketplace: {
    coston: environment === 'production'
      ? process.env.REACT_APP_PROD_MARKETPLACE_CONTRACTADDRESS
      : process.env.REACT_APP_LOCAL_MARKETPLACE_CONTRACTADDRESS,
    coston2: environment === 'production'
      ? process.env.REACT_APP_PROD_MARKETPLACE_CONTRACTADDRESS2
      : process.env.REACT_APP_LOCAL_MARKETPLACE_CONTRACTADDRESS2,
  },
  erc20: {
    coston: environment === 'production'
      ? process.env.REACT_APP_PROD_ERC20_TOKEN_CONTRACTADDRESS
      : process.env.REACT_APP_LOCAL_ERC20_TOKEN_CONTRACTADDRESS,
    coston2: environment === 'production'
      ? process.env.REACT_APP_PROD_ERC20_TOKEN_CONTRACTADDRESS2
      : process.env.REACT_APP_LOCAL_ERC20_TOKEN_CONTRACTADDRESS2,
  },
};

console.log(config);
export default config;


