import React, { useEffect, useState } from "react";
import "../../style/main.scss";

import Button from "../button";
import sad from "../../assets/icon/sad-face.svg";
import { useNavigate } from "react-router-dom";
import {
  getAllLikedArtwork,
  getAllUsers,
  getArtLastPriceFromFirebase,
} from "../../firebase/firebase"; // Import the function to fetch favorite documents
import LikesGrid from "./likesGrid";
import { useAccount, useConnect, useFeeData } from "wagmi";
import marketplaceContractABI from "../../abis/Marketplace/v2/abi.json";
import Web3 from "web3";
import Backend_url from "../../herokuUrlConfig";

import config from "../../config";
const Likes = (props) => {
  console.log(props?.address);
  const [accountAddress, setAccountAddress] = useState();
  useEffect(() => {
    console.log(props?.address, "445455");
    setAccountAddress(props?.address);
  }, [props]);

  const [address, setAddress] = useState(null);
  const updateAddressFromProps = () => {
    console.log(props, "propf..........");
    if (props?.id) {
      // alert("id addded");

      setAddress(props?.id);
    } else {
      setAddress(accountAddress);
    }
  };

  useEffect(() => {
    updateAddressFromProps();
  }, [props]);

  // contract address of market place
  const { marketplace, erc20 } = config;
  console.log(marketplace);
  console.log(erc20);

  const Marketplace_coston_contractAddress = marketplace.coston;
  const Marketplace_coston2_contractAddress = marketplace.coston2;
  const contractABI = marketplaceContractABI;

  const coston_Token = erc20.coston;
  const coston2_Token = erc20.coston2;

  // getting likes from firebase

  const [favoriteData, setFavoriteData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [userData, setUserData] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      const users = await getAllUsers();
      console.log("users", users);
      setUserData(users);
    };
    fetchUserData();
  }, []);

  // get all listed data from marketplace

  const getAllListNFTData = async (selectedNetwork, web3) => {
    if (selectedNetwork && web3 != null) {
      let MarketplaceAddress;
      if (selectedNetwork === "Coston") {
        MarketplaceAddress = Marketplace_coston_contractAddress;
      } else if (selectedNetwork === "Coston2") {
        MarketplaceAddress = Marketplace_coston2_contractAddress;
      }

      console.log("MarketplaceAddress", MarketplaceAddress);
      const contract = new web3.eth.Contract(
        marketplaceContractABI,
        MarketplaceAddress
      );

      const data = await contract.methods.getAllListedNfts().call();
      return data;
    } else {
      // setListedNfts([]);
    }
  };

  // get nft metadata from backend

  const getNftMetadata = async (tokens_uri) => {
    console.log("url: " + `${Backend_url}/getNFTMetadata`);
    console.log("tokens_uri: " + tokens_uri);
    const response = await fetch(`${Backend_url}/getNFTMetadata`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uri: tokens_uri,
      }),
    });
    const json = await response.json();
    console.log(json);

    return {
      uri: tokens_uri, // Include the URI in the result object
      data: json.data.data,
    };
  };

  // get all liked artworks from firebase

  useEffect(() => {
    async function fetchLikedArtworks() {
      try {
        setLoading(true); // Start loading state

        let res;
        if (props?.address) {
          res = await getAllLikedArtwork(props.address);
        } else if (props?.id) {
          res = await getAllLikedArtwork(props.id);
        }

        if (res && res.likedArtworks.length > 0) {
          const likedArtworks = res.likedArtworks;

          // Use Promise.all to fetch NFT metadata concurrently
          const nftPromises = likedArtworks.map((a) => getNftMetadata(a));
          const nftData = await Promise.all(nftPromises);

          setFavoriteData(nftData);
        } else {
          setFavoriteData([]); // No data found, clear favoriteData
        }

        setLoading(false); // Set loading state to false
      } catch (error) {
        console.error("Error fetching liked artworks:", error);
        setLoading(false); // Ensure loading state is reset on error
      }
    }

    fetchLikedArtworks(); // Call the fetch function
  }, [props.address, props.id]);

  const navigate = useNavigate();
  const exploreArts = () => {
    navigate("/explore");
  };

  // set listed data with each liked artwork

  const fetchData = async (nfts) => {
    let updatedNftListDetails = [];

    await Promise.all(
      nfts.map(async (nftItem) => {
        let web;
        if (nftItem.data.selectedBlockchain === "Coston2") {
          web = new Web3(process.env.REACT_APP_COSTON2_RPC_URL);
        } else {
          web = new Web3(process.env.REACT_APP_COSTON_RPC_URL);
        }
        const listedNfts = await getAllListNFTData(
          nftItem?.data.selectedBlockchain,
          web
        );

        let isListed = false;
        let isSaleListed = false;
        let isOfferListed = false;

        if (listedNfts[0]) {
          listedNfts[0]?.forEach((nft) => {
            if (nft?.uriData === nftItem?.uri) {
              isListed = true;
              isSaleListed = true;
              let updatedNft = {
                ...nft,
                ...nftItem,
                isListed: true,
                isSaleListed: true,
                listedType: "listed",
              };
              updatedNftListDetails.push(updatedNft);
            }
          });
        }

        if (listedNfts[1]) {
          listedNfts[1]?.forEach((nft) => {
            if (nft?.uriData === nftItem?.uri) {
              isListed = true;
              isOfferListed = true;
              let updatedNft = {
                ...nft,
                ...nftItem,
                isListed: true,
                isOfferListed: true,
                listedType: "listed",
              };
              updatedNftListDetails.push(updatedNft);
            }
          });
        }

        if (listedNfts[0] && listedNfts[1]) {
          if (!isListed) {
            let updatedNft = {
              ...nftItem,
              isListed: false,
              listedType: "noListed",
            };
            updatedNftListDetails.push(updatedNft);
          }
        }
      })
    );

    console.log(updatedNftListDetails, "filteredMetadata");
    updatedNftListDetails.map(async (e) => {
      console.log(e.data.artId, "id");
      let lastPrice = await getArtLastPriceFromFirebase(e.data.artId);

      if (lastPrice !== null) {
        e.data.lastPrice = lastPrice;
        console.log(`Updated element with last price: `, e);
      } else {
        console.log(`No last price found for artId: ${e.data.artId}`);
      }
    });
    console.log(updatedNftListDetails, "filteredMetadata");
    setFavoriteData(updatedNftListDetails);
    setLoading(false);
  };

  const getNamebyAddress = () => {
    console.log(props?.id, "543555555555");

    if (props?.address && !props?.id) {
      return "You haven't ";
    } else {
      const user = userData?.find((user) => user?.id === props?.id);
      const artistName = user ? user?.userName : null;
      console.log(artistName);
      console.log(props?.id, artistName, "account address");

      if (!artistName) {
        return "@" + props?.id + " hasn't ";
      } else {
        // Truncate artist name to 18 characters and add ellipses if longer
        const truncatedName = artistName.length > 18
          ? artistName.substring(0, 18) + "..."
          : artistName;

        return "@" + truncatedName + " hasn't ";
      }
    }
  };
  useEffect(() => {
    console.log(favoriteData, "favoriteData");
  }, [favoriteData]);
  return (
    <>
      {!loading && favoriteData?.length == 0 ? (
        <div className="no-content mt-5 pt-5  ">
          <p className="body-large">
            {getNamebyAddress()} <span>liked </span>
            any artwork yet.
          </p>
          <Button
            text="Explore art"
            width="166px"
            height="36px"
            className="btn-prime btn-primary"
            onClick={exploreArts}
          />
        </div>
      ) : (
        <div className="art-likes">
          <LikesGrid
            address={address}
            listedNft={favoriteData}
            id={props.id}
            loading={loading}
          />
        </div>
      )}
    </>
  );
};

export default Likes;
