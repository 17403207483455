import React, { useState, useEffect, useContext } from "react";
// import "./news.scss";
import "../style/main.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import placeholder from "../assets/images/profile-1.svg";

import news1 from "../assets/images/artwork-preview-1.png";
import news2 from "../assets/images/artwork-preview-2.png";
import news3 from "../assets/images/artwork-preview-5.png";
import news4 from "../assets/images/artwork-preview-6.png";
import { useNavigate } from "react-router-dom";
import ArtistPopUp from "./shared/artistpopup";
import userProfile from "../assets/images/face-3.png";
import verified from "../assets/icon/verified-artist-small.svg";
import lock from "../assets/icon/lock.svg";
import { Popover, Skeleton } from "antd";
import config from "../config";
import Backend_url from "../herokuUrlConfig";
import {
  getCollectionHistory,
  getAllUsers,
  getCollectionByAddress,
  getCollection,
} from "../firebase/firebase";
import Carousel from "react-bootstrap/Carousel";
import mintContractABI from "../abis/SafeMint/abi.json";
import marketplaceContractABI from "../abis/marketplace.json";

import { useAccount } from "wagmi";
import Web3 from "web3";
import { NFTContext } from "./context/appContext";
import { Icon } from "@iconify/react/dist/iconify.js";
const LatestNews = () => {
  const { marketplace, erc20 } = config;
  console.log(marketplace);
  console.log(erc20);

  const { listedNfts, userMembership } = useContext(NFTContext);

  const Marketplace_coston_contractAddress = marketplace.coston;
  const Marketplace_coston2_contractAddress = marketplace.coston2;
  const contractABI = marketplaceContractABI;

  const coston_Token = erc20.coston;
  const coston2_Token = erc20.coston2;
  // Setting for latest news slider

  const { address } = useAccount();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      const users = await getAllUsers();
      console.log("users", users);
      setUserData(users);
    };
    fetchUserData();
  }, []);

  const getNamebyAddress = (accountAddress) => {
    console.log(accountAddress);
    if (address === accountAddress) {
      return "You";
    }

    const user = userData?.find((user) => user?.id === accountAddress);
    const artistName = user ? user?.userName : "Not Found";

    console.log(accountAddress, artistName, "account address");
    return "@" + artistName;
  };

  // get artist image by address

  const getImage = (accountAddress) => {
    const user = userData?.find((user) => user?.id === accountAddress);
    const artistImage = user && user?.image ? user?.image : placeholder;

    return artistImage;
  };

  const [latestNews, setLatestNews] = useState([]);
  const navigate = useNavigate();

  // Define the convertToUnixTimestamp function
  const convertToUnixTimestamp = (timestamp) => {
    // If the timestamp is a JavaScript Date object
    if (timestamp instanceof Date) {
      return Math.floor(timestamp.getTime() / 1000);
    }

    // If the timestamp is a Firestore Timestamp object
    if (timestamp.seconds) {
      return timestamp.seconds;
    }

    // If the timestamp is in ISO string format
    return Math.floor(new Date(timestamp).getTime() / 1000);
  };

  useEffect(() => {
    const fetchCollectionHistory = async () => {
      setLoading(true);
      const collectionHistory = await getCollectionHistory();
      console.log(collectionHistory, "fetched collection history");

      // Convert timestamps to Unix format and sort by it
      const sortedCollectionHistory = collectionHistory
        .map((item) => ({
          ...item,
          unixTimestamp: convertToUnixTimestamp(item.timestamp),
        }))
        .sort((a, b) => b.unixTimestamp - a.unixTimestamp);

      // Get the first 10 items from the sorted list
      const firstTenItems = sortedCollectionHistory.slice(0, 10);
      console.log(firstTenItems, "first ten items");

      setLatestNews(firstTenItems);
      setLoading(false);
    };

    fetchCollectionHistory();
  }, []);

  useEffect(() => {
    console.log(latestNews, "lated news");
  }, [latestNews]);

  function timeAgo(timestamp) {
    const currentDate = new Date();
    const providedDate = new Date(timestamp * 1000); // Convert seconds to milliseconds

    const timeDifference = currentDate - providedDate;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (minutes <= 0) {
      return "0 minutes ago"; // Return 0 if the value is negative or zero
    } else if (minutes < 60) {
      return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
    } else if (hours < 24) {
      return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
    } else if (days < 30) {
      return `${days} ${days === 1 ? "day" : "days"} ago`;
    } else if (months < 12) {
      return `${months} ${months === 1 ? "month" : "months"} ago`;
    } else {
      return `${years} ${years === 1 ? "year" : "years"} ago`;
    }
  }

  var settings = {
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: "0px",
    autoplay: false,
    autoplaySpeed: 3000,
    fade: true,
    // cssEase: "linear",
    responsive: [
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 787,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const navigateToArt = async (item) => {
    console.log(item);
    console.log(item.action, "action");
    console.log(listedNfts, "listed nfts");

    const web3_1 = new Web3(window.ethereum);

    if (item.artworkUri && web3_1 != null) {
      // Loop through the merged data and navigate to the appropriate route
      listedNfts.forEach((nft) => {
        if (nft?.data.uriData === item?.uri) {
          console.log(nft);
          console.log(nft?.listedData?.tokenId);

          navigate(
            `/artwork/${item?.artworkUri?.selectedBlockchain}/${item?.artworkUri?.selectedCollectionId}/${nft?.data?.listedData?.tokenId}`
          );
        }
      });
    } else {
      console.log("Error: Missing artworkUri or Web3 instance.");
    }
  };

  const navigateToProfile = (account) => {
    if (account === address) {
      navigate(`/profile/${address}`, { state: { loc: "hero" } });
    } else {
      navigate(`/profile/${account}`, { state: { loc: "hero" } });
    }
  };
  useEffect(() => {
    console.log(latestNews, "latest news");
  }, [latestNews]);

  const [timeRemaining, setTimeRemaining] = useState(null);
  const [canPurchase, setCanPurchase] = useState(false);
  const [earlyAccess, setEarlyAccess] = useState(false);
  const [activeMembership, setActiveMembership] = useState(null);
  const [eligibleBuyer, setEligibleBuyer] = useState(false);
  const [updatedLatestNews, setUpdatedLatestNews] = useState(null);
  const checkAccessForAllNFTs = () => {
    const now = new Date();

    const updatedNews = latestNews.map((nft) => {
      const createdTime = new Date(nft.unixTimestamp * 1000); // Assuming the timestamp is in seconds
      const timeElapsed = now - createdTime;
      const hoursElapsed = Math.floor(timeElapsed / (1000 * 60 * 60));

      let currentMembership = null; // Will track which membership window is active
      let purchaseWindow = false;
      let totalRemainingTime = null;
      let eligibleBuyer = false;
      let timeRemaining = null;
      let earlyAccess = hoursElapsed < 24; // Early access is within 24 hours

      // Determine which membership has access based on elapsed time
      if (hoursElapsed < 6) {
        currentMembership = "Gold"; // First 6 hours for GOLD members
        purchaseWindow = userMembership === "Gold";
        totalRemainingTime = 6 * 60 * 60 * 1000 - timeElapsed;
      } else if (hoursElapsed >= 6 && hoursElapsed < 12) {
        currentMembership = "Silver"; // Next 6 hours for Silver and Gold
        purchaseWindow = ["Gold", "Silver"].includes(userMembership);
        totalRemainingTime = 12 * 60 * 60 * 1000 - timeElapsed;
      } else if (hoursElapsed >= 12 && hoursElapsed < 18) {
        currentMembership = "Bronze"; // Next 6 hours for Bronze, Silver, and Gold
        purchaseWindow = ["Gold", "Silver", "Bronze"].includes(userMembership);
        totalRemainingTime = 18 * 60 * 60 * 1000 - timeElapsed;
      } else if (hoursElapsed >= 18 && hoursElapsed < 24) {
        currentMembership = "Standard"; // Last 6 hours for Standard and others
        purchaseWindow = ["Gold", "Silver", "Bronze", "Standard"].includes(
          userMembership
        );
        totalRemainingTime = 24 * 60 * 60 * 1000 - timeElapsed;
      } else {
        currentMembership = "PUBLIC"; // After 24 hours, public access
        purchaseWindow = true; // Everyone can buy
      }

      // Calculate time remaining if the window is still open
      if (totalRemainingTime !== null) {
        const hours = Math.floor(totalRemainingTime / (1000 * 60 * 60));
        const minutes = Math.floor(
          (totalRemainingTime % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((totalRemainingTime % (1000 * 60)) / 1000);
        timeRemaining = `${hours}h ${minutes}m ${seconds}s`;
      }

      // Determine if the user is an eligible buyer
      if (
        currentMembership === "PUBLIC" ||
        userMembership === currentMembership
      ) {
        eligibleBuyer = true;
      }

      // Return updated NFT information
      return {
        ...nft, // Spread the existing NFT data
        currentMembership,
        eligibleBuyer,
        timeRemaining,
        earlyAccess,
        canPurchase: purchaseWindow,
      };
    });

    console.log(updatedNews, "updated newsssssssss");

    // Update the latest news with the new calculated values for each NFT
    setUpdatedLatestNews(updatedNews);
  };

  // Call the function inside useEffect to check access for each NFT when dependencies change
  useEffect(() => {
    if (latestNews) {
      checkAccessForAllNFTs();
      const timer = setInterval(checkAccessForAllNFTs, 1000);

      return () => clearInterval(timer);
    
    }
  }, [latestNews, userMembership]);

  useEffect(() => {
    console.log(earlyAccess, "early Access");
  }, [earlyAccess]);

  useEffect(() => {
    console.log(activeMembership, "active Membership");
    console.log(eligibleBuyer, "Eligible Buyer");
  }, [activeMembership, eligibleBuyer]);

  return (
    <>
      {latestNews?.length > 0 && (
        <section className="news  ">
          <h5 className="section-head">
            <span>latest</span>
            news
          </h5>
          <div className="news-slider">
            {loading ? (
              <div
                className="d-flex flex-column align-items-center justify-content-center"
                style={{ maxWidth: "600px" }}
              >
                <Skeleton.Avatar active size={557} shape="square" />

                <Skeleton.Input
                  block={false}
                  active
                  round
                  size="large"
                  shape="button"
                  className=" mt-2"
                  style={{
                    width: "200px",
                    height: "25px",
                    margin: "auto",
                    borderRadius: "4px",
                  }}
                />
              </div>
            ) : (
              <Carousel indicators={true} controls={true} touch={true} fade>
                {updatedLatestNews.map((item, index) => (
                  <Carousel.Item>
                    <div key={index}>
                      <div className="news-card">
                        <div className="news-header pointer position-relative">
                          {item.action === "listedOffer" ? (
                            <img
                              onClick={() => {
                                if (item.action === "Mint") {
                                  navigateToArt(item); // Call your function with item data
                                } else {
                                  navigate(
                                    `/artwork/${item?.artworkUri?.selectedBlockchain}/${item?.collectionId}/${item?.tokenId}`
                                  );
                                }
                              }}
                              src={
                                item?.artworkUri?.metadata?.data.image ||
                                item?.artworkUri?.metadata?.data.previewImg
                              }
                              alt="card image"
                              className="img-100"
                            />
                          ) : item.action === "Mint" ? (
                            <img
                              onClick={() => {
                                if (item.action === "Mint") {
                                  navigateToArt(item); // Call your function with item data
                                } else {
                                  navigate(
                                    `/artwork/${item?.artworkUri?.selectedBlockchain}/${item?.collectionId}/${item?.tokenId}`
                                  );
                                }
                              }}
                              src={
                                item?.artworkUri?.image ||
                                item?.artworkUri?.previewImg
                              }
                              alt="card image"
                              className="img-100"
                            />
                          ) : (
                            <img
                              onClick={() => {
                                if (item.action === "Mint") {
                                  navigateToArt(item); // Call your function with item data
                                } else {
                                  console.log(
                                    `/artwork/${item?.artworkUri?.selectedBlockchain}/${item?.collectionId}/${item?.tokenId}`,
                                    "testttst"
                                  );
                                  navigate(
                                    `/artwork/${item?.artworkUri?.selectedBlockchain}/${item?.collectionId}/${item?.tokenId}`
                                  );
                                }
                              }}
                              src={
                                item?.artworkUri?.image ||
                                item?.artworkUri?.previewImg
                              }
                              alt="card image"
                              className="img-100"
                            />
                          )}

                          {item?.earlyAccess  && (
                            <div className="news-status justify-content-between">
                              <div className="d-flex align-items-center  ">
                                <span className="circle me-3"></span>{" "}
                                <p className="body-medium text-white">
                                  Early access
                                </p>
                                <div
                                  className={`member-ship fw-semibold body-medium mx-2  ${
                                    item.currentMembership == "Standard"
                                      ? "standard"
                                      : item.currentMembership == "Gold"
                                      ? "gold"
                                      : item.currentMembership == "Silver"
                                      ? "silver"
                                      : item.currentMembership == "Bronze"
                                      ? "bronze"
                                      : ""
                                  }`}
                                >
                                  {item.currentMembership}
                                </div>
                                <p className="body-medium text-white d-flex gap-1">

                          {item.timeRemaining}
                                  {/* <span>4h</span>
                                  <span>30m</span>
                                  <span>24s</span> */}
                                </p>
                              </div>
                              <div>
                                {/* <Icon
                                  icon="material-symbols:lock"
                                  width="16"
                                  height="16"
                                  color="white"
                                /> */}
                                <img src={lock} alt="" className="w-75" />
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="news-body">
                          {console.log(item, "test item")}
                          <p>
                            {/* sold */}
                            {item?.action === "sold" && (
                              <>
                                <Popover
                                  placement="top"
                                  trigger="hover"
                                  content={
                                    <ArtistPopUp
                                      userProfile={getImage(item?.to)}
                                      artistName={getNamebyAddress(item?.to)}
                                      verified={verified}
                                    />
                                  }
                                >
                                  <span
                                    onClick={() => {
                                      navigateToProfile(item?.to);
                                    }}
                                    className="hover-underline pointer show-artist-popup"
                                  >
                                    {(() => {
                                      const name = getNamebyAddress(item?.to);
                                      return name.length > 14
                                        ? name.substring(0, 14) + "..."
                                        : name;
                                    })()}
                                  </span>
                                </Popover>{" "}
                                purchased{" "}
                                <span className="hover-underline pointer">
                                  <span
                                    className="hover-underline pointer"
                                    onClick={() =>
                                      navigate(
                                        `/artwork/${item?.artworkUri?.metadata?.data.selectedBlockchain}/${item?.collectionId}/${item?.tokenId}`
                                      )
                                    }
                                  >
                                    {item?.artworkUri?.artName}
                                  </span>
                                </span>{" "}
                                for {item?.price / 1000000000000000000}{" "}
                                {item?.artworkUri?.selectedBlockchain ==
                                "Coston"
                                  ? "SGB"
                                  : "FLR"}{" "}
                              </>
                            )}

                            {/* mint */}
                            {item?.action === "Mint" && (
                              <>
                                <Popover
                                  placement="top"
                                  trigger="hover"
                                  content={
                                    <ArtistPopUp
                                      userProfile={getImage(item?.from)}
                                      artistName={getNamebyAddress(item?.from)}
                                      verified={verified}
                                    />
                                  }
                                >
                                  <span
                                    onClick={() => {
                                      navigateToProfile(item?.from);
                                    }}
                                    className="hover-underline pointer show-artist-popup"
                                  >
                                    {(() => {
                                      const name = getNamebyAddress(item?.from);
                                      return name.length > 14
                                        ? name.substring(0, 14) + "..."
                                        : name;
                                    })()}
                                  </span>
                                </Popover>{" "}
                                minted{" "}
                                <span
                                  className="hover-underline pointer show-artist-popup"
                                  onClick={() => {
                                    navigateToArt(item);
                                  }}
                                >
                                  {/* {item?.artworkUri?.artName.length > 14
                                  ? item?.artworkUri?.artName + "..."
                                  : item?.artworkUri?.artName} */}
                                  {item?.artworkUri?.artName &&
                                  item.artworkUri.artName.length > 14
                                    ? item.artworkUri.artName.substring(0, 14) +
                                      "..."
                                    : item?.artworkUri?.artName}
                                </span>{" "}
                              </>
                            )}

                            {/* listed sale */}
                            {item?.action === "listedSale" && (
                              <>
                                <Popover
                                  placement="top"
                                  trigger="hover"
                                  content={
                                    <ArtistPopUp
                                      userProfile={getImage(item?.from)}
                                      artistName={getNamebyAddress(item?.from)}
                                      verified={verified}
                                    />
                                  }
                                >
                                  <span
                                    onClick={() => {
                                      navigateToProfile(item?.from);
                                    }}
                                    className="hover-underline pointer show-artist-popup"
                                  >
                                    {(() => {
                                      const name = getNamebyAddress(item?.from);
                                      return name.length > 14
                                        ? name.substring(0, 14) + "..."
                                        : name;
                                    })()}
                                  </span>
                                </Popover>{" "}
                                listed{" "}
                                <span
                                  className="hover-underline pointer show-artist-popup"
                                  onClick={() =>
                                    navigate(
                                      `/artwork/${item?.artworkUri?.selectedBlockchain}/${item?.collectionId}/${item?.tokenId}`
                                    )
                                  }
                                >
                                  {item?.artworkUri?.artName &&
                                  item.artworkUri.artName.length > 14
                                    ? item.artworkUri.artName.substring(0, 14) +
                                      "..."
                                    : item?.artworkUri?.artName}
                                </span>{" "}
                                for sale
                              </>
                            )}

                            {/* listed offer */}
                            {item?.action === "listedOffer" && (
                              <>
                                {console.log(item)}
                                <Popover
                                  placement="top"
                                  trigger="hover"
                                  content={
                                    <ArtistPopUp
                                      userProfile={getImage(item?.from)}
                                      artistName={getNamebyAddress(item?.from)}
                                      verified={verified}
                                    />
                                  }
                                >
                                  <span
                                    className="hover-underline pointer show-artist-popup"
                                    onClick={() => {
                                      navigateToProfile(item?.from);
                                    }}
                                  >
                                    {(() => {
                                      const name = getNamebyAddress(item?.from);
                                      return name.length > 14
                                        ? name.substring(0, 14) + "..."
                                        : name;
                                    })()}
                                  </span>
                                </Popover>{" "}
                                listed{" "}
                                <span
                                  className="hover-underline pointer"
                                  onClick={() =>
                                    navigate(
                                      `/artwork/${item?.artworkUri?.selectedBlockchain}/${item?.collectionId}/${item?.tokenId}`
                                    )
                                  }
                                >
                                  {item?.artworkUri?.artName}
                                </span>
                                for offer
                              </>
                            )}

                            {/* offers */}
                            {item?.action === "offer" && (
                              <>
                                <Popover
                                  placement="top"
                                  trigger="hover"
                                  content={
                                    <ArtistPopUp
                                      userProfile={getImage(item?.from)}
                                      artistName={getNamebyAddress(item?.from)}
                                      verified={verified}
                                    />
                                  }
                                >
                                  <span
                                    onClick={() => {
                                      navigateToProfile(item?.from);
                                    }}
                                    className="hover-underline pointer show-artist-popup"
                                  >
                                    {getNamebyAddress(item?.from)}
                                  </span>
                                </Popover>{" "}
                                made a bid of{" "}
                                {item?.price / 1000000000000000000}{" "}
                                {item?.artworkUri?.selectedBlockchain ==
                                "Coston"
                                  ? "SGB"
                                  : "FLR"}{" "}
                                on{" "}
                                <span
                                  className="hover-underline pointer"
                                  onClick={() =>
                                    navigate(
                                      `/artwork/${item?.artworkUri?.selectedBlockchain}/${item?.collectionId}/${item?.tokenId}`
                                    )
                                  }
                                >
                                  {item?.artworkUri?.artName}
                                </span>
                              </>
                            )}

                            {item?.action === "transfer" && (
                              <>
                                <Popover
                                  placement="top"
                                  trigger="hover"
                                  content={
                                    <ArtistPopUp
                                      userProfile={getImage(item?.from)}
                                      artistName={getNamebyAddress(item?.from)}
                                      verified={verified}
                                    />
                                  }
                                >
                                  <span
                                    onClick={() => {
                                      navigateToProfile(item?.from);
                                    }}
                                    className="hover-underline pointer show-artist-popup"
                                  >
                                    {(() => {
                                      const name = getNamebyAddress(item?.from);
                                      return name.length > 14
                                        ? name.substring(0, 14) + "..."
                                        : name;
                                    })()}
                                  </span>
                                </Popover>{" "}
                                transferred{" "}
                                <span
                                  className="hover-underline pointer"
                                  onClick={() =>
                                    navigate(
                                      `/artwork/${item?.artworkUri?.selectedBlockchain}/${item?.collectionId}/${item?.tokenId}`
                                    )
                                  }
                                >
                                  {item?.artworkUri?.artName}{" "}
                                </span>
                                {/* <span
                                onClick={() => {
                                  navigateToProfile(item?.to);
                                }}
                                className="hover-underline pointer"
                              >
                                {item?.user}
                              </span>{" "} */}
                                to{" "}
                                <span
                                  onClick={() => {
                                    navigateToProfile(item?.to);
                                  }}
                                  className="hover-underline pointer show-artist-popup"
                                >
                                  {getNamebyAddress(item?.to)}
                                </span>
                              </>
                            )}
                            {/* transfer */}
                            {item?.action === "claimNft" && (
                              <>
                                <Popover
                                  placement="top"
                                  trigger="hover"
                                  content={
                                    <ArtistPopUp
                                      userProfile={getImage(item?.to)}
                                      artistName={getNamebyAddress(item?.to)}
                                      verified={verified}
                                    />
                                  }
                                >
                                  <span
                                    onClick={() => {
                                      navigateToProfile(item?.to);
                                    }}
                                    className="hover-underline pointer show-artist-popup"
                                  >
                                    {(() => {
                                      const name = getNamebyAddress(item?.to);
                                      return name.length > 14
                                        ? name.substring(0, 14) + "..."
                                        : name;
                                    })()}
                                  </span>
                                </Popover>{" "}
                                claimed{" "}
                                <span
                                  className="hover-underline pointer"
                                  onClick={() =>
                                    navigate(
                                      `/artwork/${item?.artworkUri?.selectedBlockchain}/${item?.collectionId}/${item?.tokenId}`
                                    )
                                  }
                                >
                                  {item?.artworkUri?.artName}{" "}
                                </span>
                                {/* <span
                                onClick={() => {
                                  navigateToProfile(item?.to);
                                }}
                                className="hover-underline pointer"
                              >
                                {item?.user}
                              </span>{" "} */}
                                {/* to{" "} */}
                                {/* <span
                                onClick={() => {
                                  navigateToProfile(item?.to);
                                }}
                                className="hover-underline pointer show-artist-popup"
                              >
                                {getNamebyAddress(item?.to)}
                              </span> */}
                              </>
                            )}
                          </p>
                          <label className="small">
                            {timeAgo(item?.timestamp?.seconds)}
                          </label>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default LatestNews;
